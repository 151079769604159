.loginWrapper#wrapper #content-wrapper {
  background: url(../../Images/login-bg.jpg) center center/cover no-repeat !important;
  height: 100%;
  position: fixed;
}
.loginWrapper#wrapper #content-wrapper {
  padding-left: 0;
  background: #eef0f8;
  padding-top: 0;
  margin-top: 0;
  min-height: 100vh;
}
.loginWrapper#wrapper #content-wrapper #content {
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 0;
  margin-top: 0;
  min-height: 100vh;
}
.loginWrapper .left-130 {
  left: 0;
}
.loginWrapper .portal-heading .portal-heading-text {
  font-size: 20px;
}
.loginWrapper footer.sticky-footer {
  padding: 1rem 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Login Section */
.loginWrapper .login-section {
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 15px;
}
.loginWrapper .custom-d-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #dae0e9;
  border-radius: 10px;
}
.loginWrapper .login-section .welcome-section {
  padding: 40px 25px;
  background: #e9ecef;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: url(../../Images/signin-arrow.png);
  text-align: center;
  color: #595959;
  background-position: center 170px;
  background-repeat: no-repeat;
  background-size: 569px;
}
.loginWrapper .login-section .signin-section {
  padding: 40px 25px;
  border-radius: 10px;
  background: #fff;
}
.loginWrapper .welcome-section .heading-text {
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #3f4254;
}
.loginWrapper .signin-section .heading-text {
  font-size: 2.5rem;
  display: block;
  text-align: center;
  color: #3699ff;
  margin-bottom: 1rem;
}
.loginWrapper .welcome-section p {
  font-size: 15px;
  color: #6c757d;
  padding-top: 15px;
  text-align: center;
}
.py-3-5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.relative {
  position: relative;
}
.w-60 {
  width: 60%;
}
.logout-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  border-radius: 3px;
  color: #67a172;
  border: 1px solid #d1efdc;
  background: #ebf8f0;
  padding: 10px;
  font-size: 14px;
}
.logout-msg p > svg {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .loginWrapper#wrapper #content-wrapper {
    overflow: hidden;
  }
  .loginWrapper .welcome-section {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .loginWrapper .signin-section {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .loginWrapper .singin-info {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }
  .loginWrapper .login-box.login-sidebar {
    min-height: 100vh;
  }
  .loginWrapper .login-section {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0px, -50%);
  }
}

@media screen and (min-width: 768px) and (max-height: 481px) {
  .loginWrapper#wrapper #content-wrapper {
    overflow-y: scroll;
  }

  .loginWrapper#wrapper #content-wrapper #content {
    margin-block: 5rem;
  }

  .py-3-5 {
    padding-block: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-height: 600px) and (min-height: 480px) {
  .loginWrapper#wrapper #content-wrapper {
    overflow-y: scroll;
  }

  .loginWrapper#wrapper #content-wrapper #content {
    margin-block: 5rem;
  }
}

@media screen and (max-width: 768px) and (min-width: 991px) {
  .loginWrapper .signin-section {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .loginWrapper .signin-section,
  .welcome-section {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .loginWrapper .login-section {
    margin-top: 40%;
  }
  .loginWrapper .login-section .welcome-section {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
  .loginWrapper .login-section .welcome-section {
    padding-bottom: 0;
    background: #fff;
  }
  .loginWrapper .login-section .signin-section {
    padding-top: 0;
  }
  .loginWrapper .login-section .border-btm-1 {
    border-bottom: 1px solid #dee2e6;
  }
  .loginWrapper .custom-d-flex {
    background: #fff;
  }
  .loginWrapper .login-section .signin-section {
    border-radius: 10px;
  }
  .py-3-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

@media screen and (max-width: 380px) {
  .loginWrapper .sidebar-brand {
    padding: 8px 10px;
  }
  .loginWrapper .portal-heading .portal-heading-text {
    font-size: 16px;
  }
}
