.custom-tooltip {
    position: absolute;
    top: 24px;
    padding: .25rem .5rem;
    transform: translateX(-50%);
    z-index: 1070;
    line-height: 1.5;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #000;
    text-transform: none;
    letter-spacing: 1px;
    color: #fff;
    font-weight: normal;
}

.custom-tooltip .tooltip-inner {
    width: 120px;
}

.custom.tooltip .tooltip-inner {
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem;
}

.custom-tooltip .arrow {
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: .8rem;
    height: .4rem;
    border: .4rem solid transparent;
    border-bottom-color: #000;
}