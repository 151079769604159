.service-trending-tool-card {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

.service-trending-tool-card .card {
    box-shadow: 0 0.125rem 0.5rem rgba(33, 37, 41, 0.075);
}
