.score-card {
    min-width: 170px !important;
    min-height: 150px;
    max-width: 25%;
    margin-bottom: 1rem;
    transition: 0.3s all;
}

.score-card:hover {
    background-color: var(--bs-blue);
}

.score-card:hover p,
.score-card:hover svg path {
    color: var(--bs-white);
}

.score-card:hover .score-card-icon {
    background-color: var(--bs-white);
}

.score-card:hover .score-card-icon svg path {
    color: var(--bs-blue);
}

.score-card~.score-card {
    margin-left: 1rem;
}

.score-card-icon {
    background: var(--bs-blue);
    color: var(--bs-light);
    border-radius: .5rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}