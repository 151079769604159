*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 0.75rem;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #6c757d;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.navbar {
  height: 55px;
}

.navbar .navbar-logo {
  max-height: 22px;
  margin-right: 1rem;
}

.wrapper {
  display: flex;
  max-width: 100vw;
}

.wrapper>div {
  flex-grow: 1;
}

.wrapper .sidebar {
  background: #fff;
  max-width: 130px;
  min-width: 130px;
  width: 130px;
  border-right: 1px solid #e9ecef;
  transition: all .3s ease-in-out;
  z-index: 1000;
  height: calc(100vh - 55px);
  overflow-y: auto;
}

.wrapper .sidebar::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.wrapper .sidebar::-webkit-scrollbar-track {
  box-shadow: unset;
}

.wrapper .sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0.75rem;
}

.wrapper .sidebar:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.wrapper .sidebar:hover::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
}

.wrapper .sidebar:hover::-webkit-scrollbar-thumb:hover {
  background-color: #6c757d;
}

.wrapper .sidebar .nav {
  width: fit-content;
}

.sidebar .nav-item {
  cursor: pointer;
  min-width: 125px;
}

.wrapper .sidebar .nav .nav-item .nav-link {
  transition: all 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6c757d;
  text-align: center;
  font-size: 1rem;
  line-height: 1.3;
}

.wrapper .sidebar .nav .nav-item .nav-link .nav-icon {
  width: 50px;
  height: 50px;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #6c757d;
  background: #ecf0f1;
  box-shadow: inset 0 1px 2px rgba(33, 37, 41, 0.075);
  position: relative;
}

.wrapper .sidebar .nav .nav-item .nav-link .nav-icon .notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
  background-color: #e74c3c;
  border-radius: 50%;
}

.wrapper .sidebar .nav .nav-item .nav-link.show {
  background-color: rgba(236, 240, 241, 0.5);
}

.wrapper .sidebar .nav .nav-item .nav-link.active {
  color: #3699ff;
  background-color: #e9ecef;
  font-weight: bold;
}

.wrapper .sidebar .nav .nav-item .nav-link.active .nav-icon {
  color: inherit;
  background-color: #e9ecef;
  border: 2px solid #3699ff;
}

.wrapper .sidebar .nav .nav-item .nav-link .sub-nav {
  position: fixed;
  top: 0px;
  left: 130px;
  height: 100%;
  width: 200px;
  background-color: #fff;
  border-right: 1px solid #e9ecef;
  display: none;
  padding: .5rem;
}

.wrapper .sidebar .nav .nav-item .nav-link.show .sub-nav.show {
  display: block;
  animation: fadein 0.3s linear forwards;
}

.wrapper .sidebar .nav .nav-item .nav-link .sub-nav .nav-item .nav-link {
  text-align: left;
  align-items: flex-start;
  display: block;
  font-weight: normal;
  border-radius: .25rem;
}

.wrapper .sidebar .nav .nav-item .nav-link .sub-nav .nav-item .nav-link:hover {
  background-color: #e9ecef;
}

.wrapper .main {
  height: calc(100vh - 55px);
  padding: 1rem;
  flex-grow: 1;
  overflow-y: auto;
}

.wrapper .main.sidebar-opened {
  position: relative;
}

.wrapper .main.sidebar-open::after {
  content: '';
  position: fixed;
  top: 51px;
  left: 0;
  height: calc(100vh - 51px);
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}


.wrapper .main .bg-icon {
  background: linear-gradient(180deg, rgba(233, 236, 239, 0.3), rgba(173, 181, 189, 0.15));
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 1rem;
  box-shadow: inset 0 1px 2px rgba(33, 37, 41, 0.075);
}

.wrapper .main .page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.wrapper .main .page-header .page-header-right {
  display: flex;
  grid-gap: 0.5rem
}

.wrapper .main .page-header .page-title {
  font-size: 1.4rem;
  font-weight: bolder;
}

.wrapper .main .page-content .dashboard-card {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.wrapper .main .page-content .dashboard-card .card {
  width: 170px;
  height: 170px;
  box-shadow: 0 0.125rem 0.5rem rgba(33, 37, 41, 0.075);
}

.wrapper .main .page-content .card.user-access-card {
  border: 1px solid #dee2e6;
  cursor: pointer;
}

.wrapper .main .page-content .card.user-access-card:hover {
  background: #ecf0f1;
}

@media (max-width: 576px) {
  .wrapper .main .page-content .dashboard-card .card {
    height: auto;
  }
}

.wrapper .main .page-content .feedback-nav {
  background-color: #dee2e6;
  padding: .35rem;
  border-radius: 8px;
}

.wrapper .main .page-content .feedback-nav.nav-pills-sm {
  padding: 0.25rem;
}

.wrapper .main .page-content .feedback-nav.nav-pills-sm .nav-link {
  padding: 0.25rem 0.75rem !important;
  font-size: 0.75rem;
}

.wrapper .main .page-content .feedback-nav .nav-link {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: 0;
  border-radius: 4px;
}

/* .wrapper .main .page-content .feedback-nav .nav-item {
  background-color: #ecf0f1;
  border-radius: 25px;
  box-shadow: 0 0 10px #6c757d33;
} */

.wrapper .main .page-content .feedback-nav .nav-item~.nav-item {
  margin-left: .5rem;
}

/* .wrapper .main .page-content .feedback-nav .nav-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  margin: auto;
  height: 0;
  background-color: #3699ff;
  transition: width 0.3s ease-in-out;
} */

.wrapper .main .page-content .feedback-nav .nav-link.active {
  background-color: #3699ff;
  color: #fff;
  /* border-radius: 25px; */
}

/* .wrapper .main .page-content .feedback-nav .nav-link.active:after {
  width: 100%;
  height: 2px;
} */

.wrapper .main .page-content .feedback-card {
  box-shadow: 0 0.125rem 0.5rem rgba(33, 37, 41, 0.075);
  cursor: pointer;
}

.wrapper .main .page-content .list-view .feedback-card {
  margin-bottom: 1rem;
}

.wrapper .main .page-content .list-view .feedback-card .card-body {
  display: flex;
  grid-gap: 2rem;
}

.wrapper .main .page-content .list-view .feedback-card .card-body .feedback-info,
.wrapper .main .page-content .list-view .feedback-card .card-body .feedback-time {
  display: flex;
  align-items: center;
  white-space: nowrap;

}

.wrapper .main .page-content .list-view .feedback-card .card-body .feedback-info {
  margin-left: auto;
}

.wrapper .main .page-content .grid-view {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 1rem;
}

.wrapper .main .page-content .grid-view .feedback-card {
  width: calc(33.33333% - 0.66666rem);
  flex-grow: 1;
}

.wrapper .main .page-content .grid-view .feedback-card .card-body {
  display: flex;
  grid-gap: 0.5rem;
  flex-direction: column;
}

.wrapper .main .page-content .grid-view .feedback-card .card-body .feedback-info {
  margin-left: 0;
}

.wrapper .activity-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(54, 153, 255, 0.75);
  color: #fff;
  position: absolute;
  top: -4px;
  left: 0;
}

.wrapper .activity-item {
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 1.5rem;
}

.wrapper .activity-item:before {
  content: '';
  height: 100%;
  width: 2px;
  position: absolute;
  top: 20px;
  left: 11px;
  background-color: rgba(54, 153, 255, 0.25);
}

.file-upload {
  display: block;
  padding: 1rem;
  border: 3px dashed #dee2e6;
  text-align: center;
}

.file-upload:hover {
  background-color: rgba(54, 153, 255, 0.25);
  border-color: #3699ff;
}

.form-control-icon-end {
  position: relative;
}

.form-control-icon-end .form-control {
  padding-right: 2rem;
}

.form-control-icon-end .input-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
}

label {
  margin-bottom: .5rem;
}

.btn {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-primary,
.btn-danger {
  color: #fff !important;
}

.fade-in-up {
  animation: dropdown 0.25s forwards;
}

.input-group-text {
  height: 100%;
}

@keyframes dropdown {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* Loader */

.log-out-layer {
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

#overlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: #ffffff94;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%) rotate(135deg);
  z-index: 999;
}

.pre-loader {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pre-loader .line {
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent;
  border-bottom: 2px solid #3699ff;
}

.pre-loader .line:nth-child(1) {
  width: 20px;
  height: 20px;
  animation: anim4 600ms linear infinite;
}

.pre-loader .line:nth-child(2) {
  width: 40px;
  height: 40px;
  animation: anim4 800ms linear infinite;
}

.pre-loader .line:nth-child(3) {
  width: 60px;
  height: 60px;
  animation: anim4 1000ms linear infinite;
}

.pre-loader .line:nth-child(4) {
  width: 80px;
  height: 80px;
  animation: anim4 1200ms linear infinite;
}

.back-arrow {
  justify-content: center;
}

.back-arrow span {
  display: none;
}

.service-usage-graph .apexcharts-legend-series,
.forecast-graph .apexcharts-legend-series {
  width: calc(50% - 10px);
}

.trending-service-graph .apexcharts-legend-series,
.forecast-graph .apexcharts-legend-series {
  display: flex !important;
  align-items: center !important;
  grid-gap: .25rem;
}

.trending-service-graph .apexcharts-legend-marker {
  min-width: 12px;
}


.custom-tooltip-text {
  position: relative;
  font-size: 14px;
  margin-left: 0.5rem;
}

.custom-tooltip-info {
  position: absolute;
  left: 26px;
  top: 0px;
  bottom: 0px;
  max-width: 300px;
  width: 300px;
}

.custom-tooltip-info .modal-dialog {
  overflow: visible;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  box-shadow: 0 0 10px 5px #0001;
}

.custom-tooltip-info .modal-dialog .modal-content {
  padding: 0.5rem;
}

.react-datepicker-popper {
  z-index: 1000;
}

.prod-reg-datepicker {
  max-width: 200px;
}

@keyframes anim4 {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 575px) {
  .wrapper .sidebar {
    width: 0;
    transform: translateX(-130px);
    overflow: unset;
  }

  .wrapper .sidebar {
    position: fixed;
    top: 55px;
    left: 0;
    height: calc(100% - 55px);
  }

  .wrapper .sidebar.show {
    width: 130px;
    transform: translateX(0px);
    overflow-y: auto;
  }

  .back-arrow {
    justify-content: flex-start;
  }

  .back-arrow span {
    display: inline-block;
  }

  .wrapper .main .page-content .list-view .feedback-card .card-body {
    flex-direction: column;
    grid-gap: .5rem
  }

  .wrapper .main .page-content .list-view .feedback-card .card-body .feedback-info,
  .wrapper .main .page-content .list-view .feedback-card .card-body .feedback-time {
    margin: unset !important;
  }

  .wrapper .main .page-content .grid-view .feedback-card {
    width: calc(50% - 0.66666rem);
  }

  .wrapper .main .page-content .feedback-nav {
    grid-gap: 1rem;
  }


}