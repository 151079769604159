.date-picker,
.date-picker * {
    width: 100%;
}

.date-picker input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #ecf0f1;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.date-picker input:focus {
    color: #6c757d;
    background-color: #ecf0f1;
    border-color: #9bccff;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(54, 153, 255, 0.1);
}

.form-control {
    min-height: 38px;
}

.filter-buttons button ~ * {
    margin-left: .5rem;
}